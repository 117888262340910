<template>
<b-card no-body>
    <b-card-header class="pb-50">
        <h5>
            Filters
        </h5>
    </b-card-header>
    <b-card-body>
        <b-row>
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>Role</label>
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="roleFilter" :options="roleOptions" class="w-100" :reduce="val => val.value" @input="(val) => $emit('update:roleFilter', val)" />
            </b-col>
            
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                <label>Status</label>
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="statusFilter" :options="statusOptions" class="w-100" :reduce="val => val.value" @input="(val) => $emit('update:statusFilter', val)" />
            </b-col>
            <b-col cols="12" md="4" class="mb-md-0 mb-2" v-if="loggedUser.role == 'Super Admin'">
                <label>Company</label>
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="companyFilter" :options="companiesList" class="w-100" :reduce="val => val.value" @input="(val) => $emit('update:companyFilter', val)" />
            </b-col>
        </b-row>
    </b-card-body>
</b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from "@/auth/utils";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        vSelect,
    },
    data() {
        return {
            loggedUser: getUserData()
        }
    },
    props: {
        roleFilter: {
            type: [String, null],
            default: null,
        },
        companyFilter: {
            type: [String, null],
            default: null,
        },
        statusFilter: {
            type: [String, null],
            default: null,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        companiesList: {
            type: Array,
            required: true,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
